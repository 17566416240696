import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { SwiperSlide } from "swiper/react"
import { colors } from "../colors"
import Slider from "./Slider"

const Title = styled.h2`
  font-weight: normal;
  margin-left: 64px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    width: 768px;
    margin-left: auto;
    margin-right: auto;
  }
`

const TalleresSection = styled.section`
  margin: 0 auto 48px;
  position: relative;
  max-width: 960px;

  ::before {
    content: "";
    background-color: ${colors.bg1};
    position: absolute;
    top: 0;
    bottom: 0;
    margin: 0 -9999rem;
    padding: 0 9999rem;
  }

  .swiper-pagination {
    bottom: 0;
    background: white;
  }

  .swiper-slide {
    width: 220px;
    height: auto;

    @media (min-width: 768px) {
      width: 280px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  @media (min-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
      top: calc(50% - 10px);
      color: #acabab;
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }

    .swiper-button-lock {
      display: none !important;
    }
  }
`

const Box = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;
  border-radius: 32px;
  background: white;
  text-align: center;
  height: 100%;
  margin-left: 3px;
  margin-right: 3px;

  p {
    margin: 16px 0 32px;
    line-height: 1.3;
    color: ${colors.text};
  }

  @media (min-width: 768px) {
    padding: 24px;

    p {
      margin-top: 24px;
    }
  }

  :hover,
  :focus {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  }
`

const BoxLink = styled(Link)`
  outline: none;
`

const Pic = styled.div`
  border-radius: 23px;
  overflow: hidden;

  .gatsby-image-wrapper {
    min-height: 127px;
  }

  @media (min-width: 992px) {
    .gatsby-image-wrapper {
      min-height: 157px;
    }
  }
`

export default function TalleresSlider({ noTitle }) {
  const { allMdx } = useStaticQuery(
    graphql`
      query talleresList {
        allMdx(filter: { fileAbsolutePath: { regex: "/talleres/" } }) {
          nodes {
            frontmatter {
              slug
              cover {
                childImageSharp {
                  gatsbyImageData(
                    width: 225
                    placeholder: BLURRED
                    layout: CONSTRAINED
                  )
                }
              }
              title
            }
          }
        }
      }
    `
  )

  const sliderSettings = {
    sliderStyles: {
      padding: "24px 24px 48px",
    },
    navigation: true,
    slidesPerView: "auto",
    spaceBetween: 16,
    breakpoints: {
      768: {
        spaceBetween: 24,
      },
      940: {
        spaceBetween: 36,
      },
    },
  }

  return (
    <>
      {noTitle ? "" : <Title>Talleres</Title>}
      <TalleresSection>
        <Slider sliderSettings={sliderSettings}>
          {allMdx.nodes.map((taller, i) => (
            <SwiperSlide key={i}>
              <BoxLink to={`/talleres/${taller.frontmatter.slug}`}>
                <Box>
                  <Pic>
                    <GatsbyImage
                      image={getImage(taller.frontmatter.cover)}
                      alt={taller.frontmatter.title}
                      height={151}
                    />
                  </Pic>
                  <p>{taller.frontmatter.title}</p>
                </Box>
              </BoxLink>
            </SwiperSlide>
          ))}
        </Slider>
      </TalleresSection>
    </>
  )
}
