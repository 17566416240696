import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import content from "../content.json"
import AboutTaller from "../components/AboutTaller"
import { colors } from "../colors"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"
import TalleresSlider from "../components/TalleresSlider"

const Text = styled.p`
  padding: 0 65px 16px;
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5;
`

const Button = styled.button`
  background: ${colors.button};
  padding: 8px 24px;
  border-radius: 19px;
  font-weight: 500;
  border: none;
  margin: 0 auto 36px;
  display: block;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.1);
  }
`

export default function Talleres() {
  return (
    <Layout>
      <Seo {...content.pages.talleres.seo} />
      <AboutTaller />
      <TalleresSlider noTitle />
      <Text>{content.pages.talleres.reservarText}</Text>
      <Button>
        <Link
          to={content.pages.talleres.reservarBtn.link}
          style={{ color: colors.text }}
        >
          {content.pages.talleres.reservarBtn.text}
        </Link>
      </Button>
    </Layout>
  )
}
