import React from "react"
import styled from "styled-components"

const Text = styled.div`
  -webkit-line-clamp: ${({ more }) => (more ? "unset" : 3)};
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  line-height: 1.5;

  p:not(:last-of-type) {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    -webkit-line-clamp: ${({ more }) => (more ? "unset" : "unset")};
  }
`


export default function ReadMore({ children }) {
  return (
    <>
      <Text more={true}>{children}</Text>
    </>
  )
}
